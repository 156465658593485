import styled, { css } from 'styled-components'
import imglanding from '../imgs/alumnos.jpg'

export const MainImg = styled.div<{
  imgbg?: string
  height?: string
  position?: string
}>`
  background-image: url(${(p) => p.imgbg ?? imglanding});
  background-position: ${(p) => p.position ?? 'center top'};
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 80vh;
  height: ${(p) => p.height ?? '70%'};
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);

  @media only screen and (max-width: 770px) {
    min-height: 50vh;
    height: ${(p) => p.height ?? '50%'};
  }
`

export const MainButton = styled.button<{ disabled?: boolean }>`
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: white;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  background-color: #00a09c !important;

  ${(p) =>
    p.disabled &&
    css`
      cursor: default;
      opacity: 0.5;
    `}
`

export const ColorContainer = styled.div`
  background-color: transparent;
  background-image: linear-gradient(180deg, #00a09c 54%, #f5f5f5 100%);
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: 0px;
  margin-bottom: 40px;
  padding: 4% 0% 1% 0%;
`
