import * as crypto from 'crypto-js'

export const generateRandomString = (complexy?: number) => {
  // TODO;: hacer regez para que solo devuelva numeros y letras
  var wordArray = crypto.lib.WordArray.random(complexy ?? 100)
  var randomString = crypto.enc.Base64.stringify(wordArray).replace(
    /[^0-9a-zA-Z]/g,
    '',
  )
  return randomString
}

export function firstLetterCap(str) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export const validatePhoneNumber = (value: string) => {
  // Puedes ajustar la expresión regular según las reglas de validación deseadas
  const phoneRegex = /^[0-9]{9}$/
  return (
    phoneRegex.test(value) ||
    'Número de teléfono no válido, debe tener 9 dígitos.'
  )
}
