import footerLogo from '../imgs/Logo.png'
import * as React from 'react'
import { MainButton } from '../views/views.styles'
import { useLocation } from 'react-router'

export const Footer = () => {
  const privatesRoutes = ['/clases-reservadas']
  const location = useLocation()
  const isPrivateRoute = () =>
    privatesRoutes.some((route) => route === location.pathname)
  return (
    <footer
      className="d-flex flex-wrap justify-content-between align-items-center py-3 border-top"
      style={{
        width: '100%',
        /*       position: 'fixed',
        bottom: 0, */
        background: 'white',
        marginTop: '30px',
      }}
    >
      <div
        className="container"
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '12px',
        }}
      >
        <div className="row">
          <div className="col" style={{ maxWidth: '85px' }}>
            <div className="footer-logo-col">
              <a href="#">
                <img src={footerLogo} width={60} />
              </a>
            </div>
          </div>
          <div className="col">
            <div>Centro hípico Ciudad de Arucas</div>
            <a href="https://maps.app.goo.gl/gMmui3tBKQ7ksqVm7">
              Ctra. Arucas-Bañaderos, 35400 Arucas, Las Palmas{' '}
            </a>
          </div>
        </div>
        <div className="row">
          <div>
            <span className="mb-3 mb-md-0 text-muted">
              <a
                href="tel:660175669"
                className="blue-light text-decoration-none"
              >
                Tlf: 660175669
              </a>
            </span>
          </div>
        </div>
        {!isPrivateRoute() && (
          <MainButton
            type="submit"
            onClick={() => (window.location.href = '/login-profesores')}
          >
            Acceso Profesores
          </MainButton>
        )}
        <div className="row">
          <div className="col">
            <a href="/aviso-legal">Legal</a>
          </div>
          <div className="col">
            <a href="/politicia-cookies">Cookies</a>
          </div>
        </div>

        <div>
          <span className="mb-3 mb-md-0 text-muted">
            © 2024 - Todos los derechos reservados por{` `}
            <a href="/" className="blue-light text-decoration-none">
              Centro hípico de Arucas
            </a>
          </span>
        </div>
      </div>
    </footer>
  )
}
