import axiosApi, { dataToURLSearchParams } from '../AxiosConfig'
import { User } from '../models/users.models'

export const singIn = async ({ identification, pass }): Promise<User> => {
  const userInfo: User = await axiosApi.post(
    'users/signIn',
    dataToURLSearchParams({ identification, pass }),
  )
  return userInfo
}

export type SignUpParams = {
  identification: string
  pass: string
  id: string
  secretKey: string
  email: string
}

export const singUp = async (params: SignUpParams): Promise<string> => {
  const { identification, pass, id, secretKey, email } = params
  const userInfo: string = await axiosApi.post(
    'users/signUp',
    dataToURLSearchParams({ identification, pass, id, secretKey, email }),
  )
  return userInfo
}
