// src/App.js
import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
/* import { CustomNavbar } from '../../components/NavBar'
 */ import { /*  Card, Col, Form, Row, */ Spinner } from 'react-bootstrap'
/* import { Field } from '../../components/Field'
import { useLocation } from 'react-router' */
import 'react-calendar/dist/Calendar.css'
import { ActionSheet } from '../../components/Modal'
import { BookedClass } from '../../domain/models/classes.models'
import { useService } from '../../hooks/useService'
import { cancelClass } from '../../domain/services/classes.service'
import { englishFormatDate } from '../../utils/date'
import { MainButton } from '../views.styles'
import { ClassesContext } from '../../components/context/Classes.context'
import { SessionContext } from '../../components/context/Session.context'

export const CancelClassModal: React.FC<{
  bookedToCancel: BookedClass
  onClose: () => void
}> = ({ bookedToCancel, onClose }) => {
  const { getClassesBooked } = React.useContext(ClassesContext)
  const { isLogged } = React.useContext(SessionContext)

  const { response, state, onExecute } = useService<string>({
    callBack: () => cancelClass({ classId: bookedToCancel.id }),
    executeOnStart: false,
  })

  React.useEffect(() => {
    if (state === 'success' && response === 'Clase eliminada exitosamente.') {
      let currentDate = new Date()

      // Restar 1 año a la fecha actual
      currentDate.setFullYear(currentDate.getFullYear() - 1)
      getClassesBooked(isLogged ? currentDate : new Date())
    }
  }, [response])

  const onSubmit = () => {
    onExecute({ classId: bookedToCancel.id })
  }

  return (
    <div>
      <ActionSheet isOpen onClose={onClose} title="Cancelar clase">
        {state === 'success' && response === 'Clase eliminada exitosamente.' ? (
          <p>La clase se ha cancelado con éxito</p>
        ) : state === 'error' || response === 'Error al eliminar la clase.' ? (
          <p>
            Ha ocurrido un error cancelando tu clase, ponte en contacto con el
            centro hípico.
          </p>
        ) : (
          <>
            <p>¿Quieres cancelar la siguiente clase?</p>
            <p>Datos:</p>
            <ul>
              <li>{`Alumno: ${bookedToCancel.name} ${bookedToCancel.surname}`}</li>
              <li>{`Teléfono: ${bookedToCancel.phone}`}</li>
              <li>Día: {englishFormatDate(bookedToCancel.date)}</li>
              <li>Hora: {bookedToCancel.hour}</li>
              <li>Pista: {bookedToCancel.track}</li>
            </ul>
            <MainButton onClick={onSubmit}>
              {state === 'pending' ? <Spinner /> : 'Aceptar'}
            </MainButton>
          </>
        )}
      </ActionSheet>
    </div>
  )
}
