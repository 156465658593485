// src/App.js
import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { CustomNavbar } from '../../components/NavBar'
import imglanding from '../../imgs/pista_y_parking.jpg'
import { MainImg } from '../views.styles'
import PicaderoMediano from '../../imgs/pista-mediana.jpg'
import pistadoma from '../../imgs/pista-doma.jpg'
import pistamediana2 from '../../imgs/pista-mediana2.jpg'
import cuadrayMonta from '../../imgs/cuadras3.png'
import restaurante from '../../imgs/restaurante-interior.jpg'
import restaurante2 from '../../imgs/restaurante.jpg'
import cuadra from '../../imgs/cuadra2.jpg'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/effect-fade'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { EffectFade, Navigation, Pagination, Autoplay } from 'swiper/modules'

export function Facilities() {
  return (
    <div>
      <CustomNavbar blackTheme />
      <MainImg imgbg={imglanding} height="90%" position="center center" />

      <main>
        <div className="d-flex flex-column justify-content-center align-items-center mt-5 px-3">
          <h2 className="mb-3 mt-4">Instalaciones del centro</h2>
          <p className="text-muted">
            Nos tomamos muy enserio la felicidad y comodidad de nuestros alumnos
            y equinos.
          </p>
          <p className="text-muted">
            Es por ello que contamos con una de las instalaciones más
            actualizadas de la isla.
          </p>
          <h4 className="mb-2 mt-4">Pistas</h4>
          <p className="text-muted">
            Una pista redonda para las clases de iniciación o para dar cuerda.
          </p>
          <p className="text-muted">
            Una pista mediana para las clases de continuidad que permiten
            mejorar el nivel de los alumnos.
          </p>
          <p className="text-muted">
            Una pista grande para entrenamientos de salto, doma y concursos.
          </p>
        </div>
        <div className="mt-5">
          <Swiper
            effect={'fade'}
            navigation={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            modules={[EffectFade, Navigation, Pagination, Autoplay]}
            className="mySwiper"
          >
            <SwiperSlide>
              <MainImg imgbg={PicaderoMediano} />{' '}
            </SwiperSlide>

            <SwiperSlide>
              <MainImg imgbg={pistadoma} />
            </SwiperSlide>
            <SwiperSlide>
              <MainImg imgbg={pistamediana2} />
            </SwiperSlide>
          </Swiper>
        </div>
        <div className="d-flex flex-column justify-content-center align-items-center mt-5 px-3">
          {' '}
          <h4 className="mb-3 mt-5">Cuadras</h4>
          <p className="text-muted">
            Contamos con cuadras de diferentes tamaños, adaptadas a las
            necesidades de tu caballo. Con bebedero automático y posibilidad de
            paddok.
          </p>
        </div>

        <div className="">
          <Swiper
            navigation={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            modules={[EffectFade, Navigation, Pagination, Autoplay]}
            className="mySwiper"
          >
            <SwiperSlide>
              <MainImg imgbg={cuadrayMonta} />{' '}
            </SwiperSlide>

            <SwiperSlide>
              <MainImg imgbg={cuadra} />
            </SwiperSlide>
          </Swiper>
        </div>
        <div className="d-flex flex-column justify-content-center align-items-center mt-5 px-3">
          {' '}
          <h4 className="mb-3 mt-5">Restaurante</h4>
          <p className="text-muted">
            Abierto todos los días, perfecto para poder merendar mientras tu
            familiar está recibiendo clases o atendiendo a su caballo.
          </p>
        </div>

        <div className="">
          <Swiper
            effect={'fade'}
            navigation={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            modules={[EffectFade, Navigation, Pagination, Autoplay]}
            className="mySwiper"
          >
            <SwiperSlide>
              <MainImg imgbg={restaurante2} />
            </SwiperSlide>
            <SwiperSlide>
              <MainImg imgbg={restaurante} />
            </SwiperSlide>
          </Swiper>
        </div>
      </main>
    </div>
  )
}
