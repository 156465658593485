// src/App.js
import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
/* import { CustomNavbar } from '../../components/NavBar'
 */ import {
  Card,
  /*  Card, Col, Form, Row, */
  Form,
  ListGroup,
  Row,
  Spinner,
} from 'react-bootstrap'
/* import { Field } from '../../components/Field'
import { useLocation } from 'react-router' */
import 'react-calendar/dist/Calendar.css'
import { ActionSheet } from '../../components/Modal'
import { BookedClass } from '../../domain/models/classes.models'
import { useService } from '../../hooks/useService'
import { getClassesByPhone } from '../../domain/services/classes.service'
import { /* englishFormatDate, */ formatDate } from '../../utils/date'
import { MainButton } from '../views.styles'
import { Field } from '../../components/Field'
import { useForm, useWatch } from 'react-hook-form'

export const SeeMyClassesPublicModal: React.FC<{
  onClose: () => void
}> = ({ onClose }) => {
  // const location = useLocation()
  const {
    register,
    // trigger,
    control,
  } = useForm<{ phone: string; selectedClass: string }>({
    mode: 'all',
  })

  const watchedPhone = useWatch({
    control,
    name: 'phone',
  })

  const {
    response: classesWithTelephoneSelected,
    state,
    onExecute,
  } = useService<BookedClass[]>({
    callBack: () =>
      getClassesByPhone({ phone: watchedPhone, dateFrom: new Date() }),
    executeOnStart: false,
  })
  interface Agrupacion {
    [key: string]: BookedClass[]
  }
  function agruparPorNombre(array): Agrupacion {
    return array.reduce((acc, obj) => {
      // Si la clave del nombre aún no existe, la inicializa como un array vacío
      if (!acc[obj.name]) {
        acc[obj.name] = []
      }
      // Agrega el objeto al array correspondiente a ese nombre
      acc[obj.name].push(obj)
      return acc
    }, {})
  }

  return (
    <div>
      <ActionSheet isOpen onClose={onClose} title="Cancelar clase">
        <form onSubmit={null} className="mb-3">
          <Field control={control} label="Número de teléfono" name="phone">
            <Form.Control
              placeholder="666222888"
              {...register('phone', { required: true })}
            />
          </Field>
          <MainButton
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              onExecute({ phone: watchedPhone, dateFrom: new Date() })
            }}
            className="mt-3 mb-6"
          >
            {state === 'pending' ? (
              <Spinner />
            ) : (
              'Ver clases asociadas al teléfono'
            )}
          </MainButton>
          {classesWithTelephoneSelected && (
            <>
              <Row className="d-flex justify-content-center mt-4">
                <h4>Clases reservadas</h4>
                {Object.keys(
                  agruparPorNombre(classesWithTelephoneSelected),
                ).map((key) => (
                  <Card style={{ width: '18rem', marginTop: '16px' }}>
                    <Card.Header style={{ fontWeight: '600' }}>
                      {`Alumno: ${key} `}
                    </Card.Header>
                    {agruparPorNombre(classesWithTelephoneSelected)[key].map(
                      (clases) => (
                        <ListGroup variant="flush">
                          <ListGroup.Item>
                            <p>Día {formatDate(clases.date)}</p>
                            <p> Hora: {clases.hour}</p>
                            <p> Pista: {clases.track}</p>
                            <p> Nivel: {clases.level}</p>
                          </ListGroup.Item>
                        </ListGroup>
                      ),
                    )}
                  </Card>
                ))}
              </Row>
            </>
          )}
        </form>
      </ActionSheet>
    </div>
  )
}
