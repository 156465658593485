import Modal from 'react-bootstrap/Modal'
import * as React from 'react'

export type Props = {
  isOpen: boolean
  onClose: () => void
  children: any
  actions?: any
  title?: string
}

export const ActionSheet: React.FC<Props> = ({
  actions,
  children,
  onClose,
  isOpen,
  title,
}) => {
  return (
    <>
      <Modal show={isOpen} onHide={onClose}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{children}</Modal.Body>
        {actions && <Modal.Footer>{actions}</Modal.Footer>}
      </Modal>
    </>
  )
}
