import { englishFormatDate } from '../../utils/date'
import axiosApi, { dataToURLSearchParams } from '../AxiosConfig'
import { BookedClass, HorsesAvailable } from '../models/classes.models'

export const saveClass = async (bookedClass: BookedClass): Promise<string> => {
  console.log('bookedClass', bookedClass)
  const { date, ...rest } = bookedClass
  const response: string = await axiosApi.post(
    'classes/saveNewClass',
    dataToURLSearchParams({
      date: englishFormatDate(date),
      ...rest,
    }),
  )
  return response
}

export const getClasses = async ({
  dateFrom,
}: {
  dateFrom: Date
}): Promise<BookedClass[]> => {
  const response: { classes: BookedClass[] } = await axiosApi.post(
    'classes/getClasses',
    dataToURLSearchParams({
      dateFrom: englishFormatDate(dateFrom),
    }),
  )
  console.log('classes', response)
  const classesMapped: BookedClass[] = response.classes.map((classMap) => ({
    ...classMap,
    date: new Date(classMap.date),
  }))
  //  const classesMapped = []
  return classesMapped
}

export const getAvailableHorses = async (): Promise<HorsesAvailable[]> => {
  const response: { vacancies: HorsesAvailable[] } = await axiosApi.post(
    'classes/getHorsesAvailable',
  )
  console.log('horses', response)
  const horsesAvailable: HorsesAvailable[] = response.vacancies
  return horsesAvailable
}

export const getClassesByPhone = async ({
  phone,
  dateFrom,
}): Promise<BookedClass[]> => {
  const response: { classes: BookedClass[] } = await axiosApi.post(
    'classes/getClassesByPhone',
    dataToURLSearchParams({ phone, dateFrom: englishFormatDate(dateFrom) }),
  )
  console.log('classes', response)
  const classesMapped: BookedClass[] = response.classes.map((classMap) => ({
    ...classMap,
    date: new Date(classMap.date),
  }))
  //  const classesMapped = []
  return classesMapped
}

export const cancelClass = async ({ classId }): Promise<string> => {
  const response: string = await axiosApi.post(
    'classes/cancelClass',
    dataToURLSearchParams({ id: classId }),
  )
  return response
}
