import { Card, Col, Form, Row } from 'react-bootstrap'
import logo from '../../imgs/Logo.png'
import * as React from 'react'
import { useNavigate } from 'react-router'
import { useForm, useWatch } from 'react-hook-form'
import { useService } from '../../hooks/useService'
import { SessionContext } from '../../components/context/Session.context'
import { SignUpParams, singUp } from '../../domain/services/users.service'
import { Field } from '../../components/Field'
import { generateRandomString } from '../../utils/general'
import {
  CardStyled,
  AuthenticationWrapper,
  Body,
  ContainerXxl,
  AuthenticationInner,
} from '../LoginTeacher/LoginTeacher.styles'
import { MainButton } from '../views.styles'

export const SignUp = () => {
  const navigate = useNavigate()
  const { setSessionData } = React.useContext(SessionContext)

  const {
    register,
    control,
    formState: { errors },
    getValues,
  } = useForm<{
    email: string
    identification: string
    pass: string
    secretKey: string
    confirmPassword: string
  }>({
    mode: 'all',
  })
  const { onExecute, response } = useService<string>({
    callBack: (params: SignUpParams) => singUp(params),
  })
  const watchedPass = useWatch({
    control,
    name: 'pass',
  })
  const watchedName = useWatch({
    control,
    name: 'identification',
  })
  React.useEffect(() => {
    if (response === 'OK') {
      setSessionData({ identification: watchedName, name: watchedName }, () =>
        navigate('/clases-reservadas'),
      )
    }
  }, [response])

  const onSubmit = (values) => {
    const { email, identification, pass, secretKey } = values
    console.log('values', values)

    onExecute({
      id: generateRandomString(25),
      email,
      identification,
      secretKey,
      pass,
    })
  }
  return (
    <>
      <Body>
        <ContainerXxl>
          <AuthenticationWrapper>
            <AuthenticationInner>
              <CardStyled>
                <form onSubmit={null}>
                  <Card.Body>
                    <Row className="mb-4">
                      <Col lg={5}>
                        <a href="/" className="app-brand-link gap-2">
                          <img src={logo} width={'80px'} />
                        </a>
                      </Col>
                      <Col lg={7} className="d-flex align-items-center">
                        <h6 className="mb-2">
                          Gestiona a los alumnos del centro hípico de arucas.
                        </h6>
                      </Col>
                    </Row>

                    <div className="mb-3">
                      <Field
                        control={control}
                        label="Nombre de usuario *"
                        name="identification"
                      >
                        <Form.Control
                          placeholder="Pj: Alias"
                          {...register('identification', { required: true })}
                        />
                      </Field>
                      <Field control={control} label="Email" name="email">
                        <Form.Control
                          placeholder="Pj: ejemplo@email.com"
                          {...register('email', { required: true })}
                        />
                      </Field>
                      <Field
                        control={control}
                        label="Clave secreta"
                        name="email"
                      >
                        <Form.Control
                          placeholder="xxxxxx"
                          {...register('secretKey', { required: true })}
                        />
                      </Field>

                      <div className="form-password-toggle">
                        <Field
                          control={control}
                          label="Contraseña"
                          name="password"
                        >
                          <Form.Control
                            type="password"
                            {...register('pass', { required: true })}
                          />
                        </Field>
                      </div>
                      <div className="mb-3 form-password-toggle">
                        <Field
                          control={control}
                          label="Repite contraseña"
                          name="confirmPassword"
                        >
                          <Form.Control
                            type="password"
                            {...register('confirmPassword', {
                              validate: (value) => {
                                if (value !== watchedPass) {
                                  return 'Las contraseñas no coinciden'
                                }
                                return true
                              },
                            })}
                          />
                        </Field>
                      </div>
                    </div>

                    <div className="mb-3">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="terms-conditions"
                          name="terms"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="terms-conditions"
                        >
                          Acepto
                          {/*  <a href="/politica-de-privacidad"> */}
                          {` las políticas de privacidad.`}
                          {/*     </a> */}
                        </label>
                      </div>
                    </div>
                    <MainButton
                      className="d-grid w-100"
                      disabled={Object.keys(errors).length > 0}
                      type={'button'}
                      onClick={() => onSubmit(getValues())}
                    >
                      Dar de alta
                    </MainButton>

                    <p className="text-center mt-3">
                      <span>¿Ya tienes una cuenta?</span>
                      <a href="/login-profesores">
                        <span>Accede aquí!</span>
                      </a>
                    </p>
                  </Card.Body>
                </form>
              </CardStyled>
            </AuthenticationInner>
          </AuthenticationWrapper>
        </ContainerXxl>
      </Body>
    </>
  )
}
