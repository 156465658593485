export const useApiFake = true
const isLocalHost = window.location.hostname === 'localhost'

export const getBaseUrl = () => {
  return isLocalHost
    ? `http://localhost:3000/apifake/`
    : `https://centrohipicociudaddearucas.com/api/`
}

export const getExtension = () => (isLocalHost ? `json` : `php`)
