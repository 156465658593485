import React /* , { Suspense }  */ from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import '@fontsource/manrope'
import '@fontsource/manrope/400.css'
import '@fontsource/manrope/500.css'
import '@fontsource/manrope/600.css'
import '@fontsource/manrope/700.css'
import { Landing } from './views/Landing/Landing'
import { Services } from './views/Services/Services'
import { Facilities } from './views/Facilites/Facilites'
import { BookYourClass } from './views/BookYourClass/BookYourClass'
import { PrivateRoute } from './components/PrivateRoute'
import { ClassBooked } from './views/PrivateZone/ClassBooked/ClassBooked'
import { ErrorBoundary } from './components/ErrorBoundary'
import { SessionProvider } from './components/context/Session.context'
import { Footer } from './components/Footer'
import { LoginForm } from './views/LoginTeacher/LoginTeacer'
import { ClassesProvider } from './components/context/Classes.context'
import { SignUp } from './views/SignUp/SignUp'
import { Cookies } from './views/Cookies/Cookies'
import { Legal } from './views/Legal/Legal'

function App() {
  return (
    <BrowserRouter>
      <div
        className="App"
        style={{ /* paddingBottom: '150px', */ height: '100%' }}
      >
        <ErrorBoundary>
          <SessionProvider>
            <Routes>
              <Route path="/" element={<Landing />} />
              <Route path="/servicios" element={<Services />} />
              <Route path="/instalaciones" element={<Facilities />} />
              <Route
                path="/reserva-tu-clase"
                element={
                  <ClassesProvider>
                    <BookYourClass />
                  </ClassesProvider>
                }
              />
              <Route path="/login-profesores" element={<LoginForm />} />
              <Route path="/dar-de-alta-profesores" element={<SignUp />} />
              <Route path="/aviso-legal" element={<Legal />} />
              <Route path="/politicia-cookies" element={<Cookies />} />
              <Route element={<PrivateRoute />}>
                <Route
                  path="/clases-reservadas"
                  element={
                    <ClassesProvider>
                      <ClassBooked />
                    </ClassesProvider>
                  }
                />
                <Route
                  path="/plazas-para-clases"
                  element={
                    <ClassesProvider>
                      <>Plazas</>
                    </ClassesProvider>
                  }
                />
              </Route>
            </Routes>
          </SessionProvider>
        </ErrorBoundary>
        <Footer />
      </div>
    </BrowserRouter>
  )
}

export default App
