import * as React from 'react'
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import { NavbarStyled } from './Navbar.styles'
import { Col, Row } from 'react-bootstrap'
import { MainButton } from '../views/views.styles'
import './general.css'
import logo from '../imgs/Logo.png'
import { CancelClass } from '../views/CancelClass/CancelClass'
import { SessionContext } from './context/Session.context'
import { SeeMyClassesPublicModal } from '../views/SeeMyClasses/SeeMyClasses'

export function CustomNavbar({ blackTheme }: { blackTheme?: boolean }) {
  const [modalOpened, setModalOpened] = React.useState<
    'none' | 'cancel' | 'seeClasses'
  >('none')
  const { cleanSessionData, isLogged } = React.useContext(SessionContext)
  const NavBarContainer = blackTheme ? NavbarStyled : Navbar
  const stylesBrand = blackTheme ? { color: 'white' } : null
  const stylesContainer = blackTheme
    ? null
    : { borderBottom: '2px solid rgb(240 238 238' }

  const isPrivateRoute = isLogged
  return (
    <NavBarContainer
      expand="lg"
      style={{ ...stylesContainer, zIndex: '10' } as any}
      className={blackTheme ? 'bg-body-tertiary' : undefined}
      /*   bg="dark" */
      variant={blackTheme ? 'dark' : undefined}
    >
      <CancelClass
        onFinishAction={() => setModalOpened('none')}
        cancelAction={modalOpened === 'cancel'}
      />
      {modalOpened === 'seeClasses' && (
        <SeeMyClassesPublicModal onClose={() => setModalOpened('none')} />
      )}
      <Container>
        <Navbar.Brand
          href="/"
          style={{ ...stylesBrand } as any}
          id={'navBarItem'}
        >
          <img src={logo} height={'25px'} />
          Centro hípico de Arucas
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        {isPrivateRoute ? (
          <Navbar.Collapse id="basic-navbar-nav" data-bs-theme="dark">
            <Nav
              className="me-auto"
              style={{ width: '100%', justifyContent: 'space-between' }}
            >
              <Container className="d-flex align-items-center">
                <Row xs={1} sm={1} md={3}>
                  <Col>
                    <Nav.Link
                      id={'navBarItem'}
                      href="/clases-reservadas"
                      style={{ ...stylesBrand } as any}
                    >
                      Clases reservadas
                    </Nav.Link>
                  </Col>
                  <Col>
                    <Nav.Link
                      id={'navBarItem'}
                      href="/plazas-para-clases"
                      disabled
                      style={{ ...stylesBrand } as any}
                    >
                      Editar plazas
                    </Nav.Link>
                  </Col>
                </Row>
                <MainButton
                  type="submit"
                  style={{
                    maxWidth: '150px',
                    width: '100%',
                    opacity: 1,
                    background: '#00a09c !important',
                  }}
                  onClick={() => {
                    cleanSessionData()
                    window.location.href = '/'
                  }}
                >
                  Cerrar sesión
                </MainButton>
              </Container>
            </Nav>
          </Navbar.Collapse>
        ) : (
          <Navbar.Collapse id="basic-navbar-nav" data-bs-theme="dark">
            <Nav
              className="me-auto"
              style={{ width: '100%', justifyContent: 'space-between' }}
            >
              <Container className="d-flex align-items-center">
                <Row xs={1} sm={1} md={4}>
                  <Col>
                    <Nav.Link
                      id={'navBarItem'}
                      href="/servicios"
                      style={{ ...stylesBrand } as any}
                    >
                      Servicios
                    </Nav.Link>
                  </Col>
                  <Col>
                    <Nav.Link
                      id={'navBarItem'}
                      href="/instalaciones"
                      style={{ ...stylesBrand } as any}
                    >
                      Instalaciones
                    </Nav.Link>
                  </Col>
                  <Col>
                    <Nav.Link
                      id={'navBarItem'}
                      // href="/instalaciones"
                      onClick={() => setModalOpened('cancel')}
                      style={{ whiteSpace: 'nowrap', ...stylesBrand } as any}
                    >
                      Cancelar clase
                    </Nav.Link>
                  </Col>
                  <Col>
                    <Nav.Link
                      id={'navBarItem'}
                      // href="/instalaciones"
                      onClick={() => setModalOpened('seeClasses')}
                      style={{ whiteSpace: 'nowrap', ...stylesBrand } as any}
                    >
                      Ver clases
                    </Nav.Link>
                  </Col>
                </Row>
              </Container>
              <MainButton
                type="submit"
                style={{
                  maxWidth: '150px',
                  width: '100%',
                  opacity: 1,
                  background: '#00a09c !important',
                }}
                onClick={() => (window.location.href = '/reserva-tu-clase')}
              >
                Reserva tu clase
              </MainButton>
            </Nav>
          </Navbar.Collapse>
        )}
      </Container>
    </NavBarContainer>
  )
}
