import { Badge, Form } from 'react-bootstrap'
import * as React from 'react'
// import { TooltipHover } from './Tooltip'
import { useController } from 'react-hook-form'

export const Field = ({
  children,
  tooltip,
  label,
  name,
  control,
}: {
  children: React.ReactNode
  label?: string
  name: string
  tooltip?: string
  control?: any
}) => {
  const { fieldState } = useController({
    name,
    control,
  })

  const renderLabel = () => {
    /*  if (!!tooltip) {
      return <TooltipHover title={label} withInfoIcon info={tooltip} />
    } */
    return label
  }
  return (
    <div>
      {!!label && <Form.Label className="mt-3">{renderLabel()}</Form.Label>}

      {children}
      {fieldState?.error?.type === 'required' && fieldState.isTouched && (
        <Badge bg="danger" className="me-1 mt-2">
          Campo obligatorio
        </Badge>
      )}
      {fieldState?.error?.type === 'validate' && fieldState.isTouched && (
        <Badge bg="danger" className="me-1 mt-2">
          {fieldState?.error?.message}
        </Badge>
      )}
    </div>
  )
}
