import { Card, Col, Row } from 'react-bootstrap'
/* import "../PrivateZone/css/pages/page-auth.css"; */
import {
  AuthenticationWrapper,
  Body,
  ContainerXxl,
  AuthenticationInner,
  CardStyled,
} from './LoginTeacher.styles'
import * as React from 'react'
import { useService } from '../../hooks/useService'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router'
import logo from '../../imgs/Logo.png'
import { MainButton } from '../views.styles'
import { User } from '../../domain/models/users.models'
import { SessionContext } from '../../components/context/Session.context'
import { singIn } from '../../domain/services/users.service'

type FormType = { identification: string; password: string }

export const LoginForm = () => {
  const { onExecute, response } = useService<User>({
    callBack: singIn,
  })
  const { setSessionData } = React.useContext(SessionContext)
  const navigate = useNavigate()

  React.useEffect(() => {
    if (!!response.id) {
      setSessionData(response, () => navigate('/clases-reservadas'))
    }
  }, [response])

  const {
    register,
    trigger,
    formState: { errors },
    /*  handleSubmit, */
    getValues,
  } = useForm<FormType>({
    mode: 'all',
    defaultValues: {
      identification: '',
      password: '',
    },
  })

  React.useEffect(() => {
    const inputs = Object.keys(getValues())
    trigger(inputs as any)
  }, [])

  const onLogin = (values: FormType) => {
    onExecute({ identification: values.identification, pass: values.password })
  }
  return (
    <Body>
      <ContainerXxl>
        <AuthenticationWrapper className="container-p-y">
          <AuthenticationInner>
            <CardStyled>
              <Card.Body>
                <Row className="mb-4">
                  <Col lg={5}>
                    <a href="/" className="app-brand-link gap-2">
                      <img src={logo} width={'80px'} />
                    </a>
                  </Col>
                  <Col lg={7} className="d-flex align-items-center">
                    <h6 className="mb-2">
                      Gestiona a los alumnos del centro hípico de arucas.
                    </h6>
                  </Col>
                </Row>
                <form onSubmit={null}>
                  <div className="mb-3">
                    <label htmlFor="email" className="form-label">
                      Email
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="email"
                      name="email-username"
                      placeholder="Introduce tu email"
                      {...register(`identification`, {
                        required: true,
                        minLength: 4,
                      })}
                      /*    autofocus */
                    />
                  </div>
                  <div className="mb-3 form-password-toggle">
                    <div className="d-flex justify-content-between">
                      <label className="form-label" htmlFor="password">
                        Contraseña
                      </label>
                      {/*    <a href="auth-forgot-password-basic.html">
                        <small>¿Olvidaste tu contraseña?</small>
                      </a> */}
                    </div>
                    <div className="input-group input-group-merge">
                      <input
                        type="password"
                        id="password"
                        className="form-control"
                        name="password"
                        placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                        aria-describedby="password"
                        {...register(`password`, {
                          required: true,
                          minLength: 4,
                        })}
                      />
                      <span className="input-group-text cursor-pointer">
                        <i className="bx bx-hide"></i>
                      </span>
                    </div>
                  </div>
                  {/*   <div className="mb-3">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="remember-me"
                      />
                      <label className="form-check-label" htmlFor="remember-me">
                        {' '}
                        Remember Me{' '}
                      </label>
                    </div>
                  </div> */}
                  <div className="mb-3 mt-5">
                    <MainButton
                      className="d-grid w-100"
                      type="button"
                      disabled={Object.keys(errors).length > 0}
                      onClick={() => onLogin(getValues())}
                    >
                      Acceder
                    </MainButton>
                  </div>

                  <p className="text-center">
                    <span>¿Sin cuenta aún?</span>
                    <a href="/dar-de-alta-profesores">
                      <span> Date de alta</span>
                    </a>
                  </p>
                </form>
              </Card.Body>
            </CardStyled>
          </AuthenticationInner>
        </AuthenticationWrapper>
      </ContainerXxl>
    </Body>
  )
}
