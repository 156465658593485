// src/App.js
import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
/* import { CustomNavbar } from '../../components/NavBar'
 */ import {
  /*  Card, Col, Form, Row, */
  Form,
  Spinner,
} from 'react-bootstrap'
/* import { Field } from '../../components/Field'
import { useLocation } from 'react-router' */
import 'react-calendar/dist/Calendar.css'
import { ActionSheet } from '../../components/Modal'
import { BookedClass } from '../../domain/models/classes.models'
import { useService } from '../../hooks/useService'
import { getClassesByPhone } from '../../domain/services/classes.service'
import { /* englishFormatDate, */ formatDate } from '../../utils/date'
import { MainButton } from '../views.styles'
import { Field } from '../../components/Field'
import { useForm, useWatch } from 'react-hook-form'
import { validatePhoneNumber } from '../../utils/general'

export const CancelClassPublicModal: React.FC<{
  onClose: () => void
  onSelectToCancel: (bookedToCancel: BookedClass) => void
}> = ({ onClose, onSelectToCancel }) => {
  // const location = useLocation()
  const {
    register,
    // trigger,
    control,
    getValues,
  } = useForm<{ phone: string; selectedClass: string }>({
    mode: 'all',
  })

  const watchedPhone = useWatch({
    control,
    name: 'phone',
  })

  const watchedClass = useWatch({
    control,
    name: 'selectedClass',
  })

  const {
    response: classesWithTelephoneSelected,
    state,
    onExecute,
  } = useService<BookedClass[]>({
    callBack: () =>
      getClassesByPhone({ phone: watchedPhone, dateFrom: new Date() }),
    executeOnStart: false,
  })

  const getClass = (id: string) =>
    classesWithTelephoneSelected.find((classes) => classes.id === id)

  return (
    <div>
      <ActionSheet isOpen onClose={onClose} title="Cancelar clase">
        <form onSubmit={null} className="mb-3">
          <Field control={control} label="Número de teléfono" name="phone">
            <Form.Control
              placeholder="666222888"
              {...register('phone', {
                required: 'Este campo es obligatorio',
                validate: validatePhoneNumber,
              })}
            />
          </Field>
          <MainButton
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              onExecute({ phone: watchedPhone, dateFrom: new Date() })
            }}
            className="mt-3 mb-6"
          >
            {state === 'pending' ? (
              <Spinner />
            ) : (
              'Ver clases asociadas al teléfono'
            )}
          </MainButton>
          {classesWithTelephoneSelected && (
            <>
              <p className="mt-3 mb-6">Selecciona que clase quieres cancelar</p>
              <Field control={control} name="selectedClass" label="">
                <Form.Select {...register('selectedClass')}>
                  {classesWithTelephoneSelected.map((type) => (
                    <option value={type.id}>
                      <span>{`Día ${formatDate(type.date)},  Hora: ${
                        type.hour
                      }, Alumno: ${type.name}`}</span>
                    </option>
                  ))}
                </Form.Select>
              </Field>
              <MainButton
                className="mt-3 mb-6"
                disabled={watchedClass?.length === 0}
                onClick={() =>
                  onSelectToCancel(getClass(getValues().selectedClass))
                }
              >
                Cancelar clase
              </MainButton>
            </>
          )}
        </form>
      </ActionSheet>
    </div>
  )
}
