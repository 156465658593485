import * as React from 'react'
/* import { User } from '../../models/users.models'
import { useService } from '../../hooks/useService'
import { getuserInfo } from '../../domain/services/users.service' */
import Spinner from 'react-bootstrap/Spinner'
import { User } from '../../domain/models/users.models'

export const identificationSessionKey = 'identification'
export type SessionData = User

export interface SessionContextType {
  isLogged: boolean
  sessionData: SessionData
  setSessionData: (sessionData: SessionData, callback?: () => void) => void
  cleanSessionData: () => void
}

const SessionContextInfo: SessionContextType = {
  isLogged: false,
  sessionData: null,
  setSessionData: (sessionData: SessionData, callback?: () => void) =>
    undefined,
  cleanSessionData: () => undefined,
}

export const SessionContext =
  React.createContext<SessionContextType>(SessionContextInfo)

export const SessionProvider = ({ children }) => {
  const [sessionData, setSessionData] = React.useState<SessionData>()
  const [isLogged, setIsLogged] = React.useState<boolean>(false)
  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  /*   const { onExecute, response } = useService({ callBack: getuserInfo })

  React.useEffect(() => {
    if (!!response) {
      saveSessionInfo(response, () => setIsLoading(false))
    }
  }, [response]) */

  React.useEffect(() => {
    /*   let identificationSession = sessionStorage.getItem(identificationSessionKey)
    if (!!identificationSession) {
      onExecute(identificationSession)
      return
    } */
    setIsLoading(false)
  }, [])

  const saveSessionInfo = async (
    sessionData: SessionData,
    callback?: () => void,
  ) => {
    sessionStorage.setItem(identificationSessionKey, sessionData.identification)
    await setSessionData(sessionData)
    await setIsLogged(true)
    if (callback) {
      await callback()
    }
  }

  const cleanSessionData = () => {
    sessionStorage.removeItem(identificationSessionKey)
    setSessionData(null)
    setIsLogged(false)
  }
  return (
    <SessionContext.Provider
      value={{
        isLogged,
        sessionData,
        setSessionData: saveSessionInfo,
        cleanSessionData,
      }}
    >
      {isLoading ? <Spinner animation="border" /> : children}
    </SessionContext.Provider>
  )
}
