import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { CancelClassPublicModal } from './CancelClassPublicModal'
import { BookedClass } from '../../domain/models/classes.models'
import { CancelClassModal } from './CancelClassModal'
import { usePrevious } from '../../hooks/usePrevious'

type ModalOpened = 'none' | 'selectClass' | 'cancelClass'

export const CancelClass: React.FC<{
  selectedClassByTeacher?: BookedClass
  cancelAction: boolean
  onFinishAction: () => void
}> = ({ selectedClassByTeacher = null, cancelAction, onFinishAction }) => {
  const [selectedClass, setSelectedClass] = React.useState<BookedClass>(
    selectedClassByTeacher,
  )
  const [modalOpened, setModalOpened] = React.useState<ModalOpened>('none')
  // const location = useLocation()

  /*   const {response,  state, onExecute } = useService<{ ok: string }>({
    callBack: cancelClass,
    executeOnStart: false,
  }) */
  const previousCancelAction = usePrevious(cancelAction)
  React.useEffect(() => {
    if (cancelAction !== previousCancelAction && cancelAction) {
      setModalOpened(!!selectedClassByTeacher ? 'cancelClass' : 'selectClass')
    }
  }, [cancelAction])

  const onSelectedClass = (classToSet: BookedClass) => {
    setSelectedClass(classToSet)
    setModalOpened('cancelClass')
  }

  return (
    <>
      {modalOpened === 'selectClass' && (
        <CancelClassPublicModal
          onSelectToCancel={onSelectedClass}
          onClose={() => {
            setModalOpened('none')
            onFinishAction()
          }}
        />
      )}
      {modalOpened === 'cancelClass' && (
        <CancelClassModal
          bookedToCancel={selectedClass}
          onClose={() => {
            setModalOpened('none')
            setSelectedClass(null)
            onFinishAction()
          }}
        />
      )}
    </>
  )
}
