// src/App.js
import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { CustomNavbar } from '../../components/NavBar'
import { Card, Container, Row } from 'react-bootstrap'
import PicaderoRedondo from '../../imgs/PicaderoRedondo.png'
import PicaderoMediano from '../../imgs/PicaderoMediano.png'
import cursilloverano from '../../imgs/cursilloverano.png'
import cuadrayMonta from '../../imgs/cuadras3.png'
import cuadra from '../../imgs/cuadra2.jpg'
import niniomirandopista from '../../imgs/niniomirandopista.png'
import { MainButton } from '../views.styles'
import { useNavigate } from 'react-router'
import { ColWithMarginTop } from '../../components/StyledComponents'

export function Services() {
  const navigate = useNavigate()
  return (
    <div>
      <CustomNavbar />

      <div className="d-flex flex-column justify-content-center align-items-center container mt-5">
        <h1 style={{ color: '#00a09c' }}>¿Qué podemos ofrecerte?</h1>
        <p style={{ color: 'gray' }}>
          Estos son los servicios que ofrecemos en el centro hípico Ciudad de
          Arucas.
        </p>
        <h3 className="mb-4" style={{ color: '#525252' }}>
          Escuela
        </h3>
        <p className="text-muted">
          En nuestra escuela te enseñaremos las bases de la equitación, las
          cuales te permitirán acabar competiendo en el salto o la doma. Las
          clases pueden ser con un caballo de la escuela, o con tu propio
          caballo.
        </p>
        <Container className="mb-4">
          <Row>
            <ColWithMarginTop className="d-flex justify-content-center">
              <Card
                style={{
                  cursor: 'pointer,',
                  width: '18rem',
                  /* flexDirection: 'row', */
                  boxShadow: '0px 12px 18px -6px rgba(0,0,0,0.3)',
                }}
                onClick={() =>
                  navigate('/reserva-tu-clase', { state: { track: 'Pequeña' } })
                }
              >
                <Card.Img
                  variant="top"
                  src={PicaderoRedondo}
                  style={{ width: '18rem', height: 'fit-content' }}
                />
                <Card.Body>
                  <Card.Title style={{ color: '#525252' }}>
                    Clases de iniciación
                  </Card.Title>
                  <Card.Text style={{ color: 'gray' }}>
                    Clases ideales para empezar en el mundo ecuestre, para
                    empezar con las bases y poder continuar pasando a la pista
                    mediana donde progresar en el salto o en la doma clásica.
                  </Card.Text>
                  <MainButton disabled>Reservar clase</MainButton>
                </Card.Body>
              </Card>
            </ColWithMarginTop>
            <ColWithMarginTop className="d-flex justify-content-center">
              <Card
                style={{
                  cursor: 'pointer',
                  width: '18rem',
                  /* flexDirection: 'row', */
                  boxShadow: '0px 12px 18px -6px rgba(0,0,0,0.3)',
                }}
                onClick={() =>
                  navigate('/reserva-tu-clase', { state: { track: 'Mediana' } })
                }
              >
                <Card.Img
                  variant="top"
                  src={PicaderoMediano}
                  style={{ width: '18rem', height: 'fit-content' }}
                />
                <Card.Body>
                  <Card.Title style={{ color: '#525252' }}>
                    Clases en pista mediana
                  </Card.Title>
                  <Card.Text style={{ color: 'gray' }}>
                    Clases para personas con algo de nivel montando, que podrán
                    continuar mejorando y aspirar a competir.
                  </Card.Text>
                  <MainButton>Reservar clase</MainButton>
                </Card.Body>
              </Card>
            </ColWithMarginTop>
          </Row>
        </Container>
        <h3 className="mb-4 mt-4" style={{ color: '#525252' }}>
          Cuidado de tu caballo
        </h3>
        <p className="text-muted">
          Nos encargamos de cubrir el 100% de necesidades de tu equino,
          partiendo de su alimentación, a sus necesidades deportivas y de salud.
        </p>
        <Container className="mb-4">
          <Row>
            <ColWithMarginTop className="d-flex justify-content-center">
              <Card
                style={{
                  width: '18rem',
                  /* width: '18rem',  */ /* flexDirection: 'row', */
                  boxShadow: '0px 12px 18px -6px rgba(0,0,0,0.3)',
                }}
              >
                <Card.Img
                  variant="top"
                  src={cuadra}
                  style={{ width: '18rem', height: 'fit-content' }}
                />
                <Card.Body>
                  <Card.Title style={{ color: '#525252' }}>Básico</Card.Title>
                  <Card.Text style={{ color: 'gray' }}>
                    <ol>
                      <li>Cuadra</li>
                      <li>Limpieza del box</li>
                      <li>Alimentación</li>
                    </ol>
                  </Card.Text>
                </Card.Body>
              </Card>
            </ColWithMarginTop>
            <ColWithMarginTop className="d-flex justify-content-center">
              <Card
                style={{
                  /* width: '18rem',  */ /* flexDirection: 'row', */
                  boxShadow: '0px 12px 18px -6px rgba(0,0,0,0.3)',
                }}
              >
                <Card.Img
                  variant="top"
                  src={cuadrayMonta}
                  style={{ width: '18rem', height: 'fit-content' }}
                />
                <Card.Body>
                  <Card.Title style={{ color: '#525252' }}>Extras</Card.Title>
                  <Card.Text style={{ color: 'gray' }}>
                    <ol>
                      <li>Caminador</li>
                      <li>Clases de doma</li>
                      <li>Clases de salto</li>
                      <li>Monta</li>
                    </ol>
                  </Card.Text>
                  {/*                   <Button variant="primary">Reservar clase</Button>
                   */}{' '}
                </Card.Body>
              </Card>
            </ColWithMarginTop>
          </Row>
        </Container>
        <h3 className="mb-4 mt-4" style={{ color: '#525252' }}>
          Cursillos
        </h3>
        <p className="text-muted">
          Permite que tu hijo disfrute en época de vacaciones practicando
          deporte junto a su animal favorito, mientras tu continuas con tu
          rutina por la mañana. Estos cursillos son perfectos tanto para mejorar
          la técnica de los alumnos ya avanzados, como para niños que acaban de
          emepezar a montar.
        </p>
        <Container>
          <Row>
            <ColWithMarginTop className="d-flex justify-content-center">
              <Card
                style={{
                  width: '18rem',
                  /* width: '18rem',  */ /* flexDirection: 'row', */
                  boxShadow: '0px 12px 18px -6px rgba(0,0,0,0.3)',
                }}
              >
                <Card.Img
                  variant="top"
                  src={cursilloverano}
                  style={{ width: '18rem', height: 'fit-content' }}
                />
                <Card.Body>
                  <Card.Title style={{ color: '#525252' }}>
                    Cursillo de verano
                  </Card.Title>
                  <Card.Text style={{ color: 'gray' }}>
                    Cursillo con las siguientes adaptadas para grupos de todos
                    los niveles:
                    <ol>
                      <li>Clases de montar</li>
                      <li>Manejo y cuidado del caballo</li>
                      <li>Clases teóricas</li>
                      <li>Juegos</li>
                    </ol>
                  </Card.Text>
                  {/*                   <Button variant="primary">Reservar clase</Button>
                   */}{' '}
                </Card.Body>
              </Card>
            </ColWithMarginTop>
            <ColWithMarginTop className="d-flex justify-content-center">
              <Card
                style={{
                  width: '18rem',
                  /* width: '18rem',  */ /* flexDirection: 'row', */
                  boxShadow: '0px 12px 18px -6px rgba(0,0,0,0.3)',
                }}
              >
                <Card.Img
                  variant="top"
                  src={niniomirandopista}
                  style={{ width: '18rem', height: 'fit-content' }}
                />
                <Card.Body>
                  <Card.Title style={{ color: '#525252' }}>
                    Cursillo de invierno
                  </Card.Title>
                  <Card.Text style={{ color: 'gray' }}>
                    Cursillo con las siguientes adaptadas para grupos de todos
                    los niveles:
                    <ol>
                      <li>Clases de montar</li>
                      <li>Manejo y cuidado del caballo</li>
                      <li>Clases teóricas</li>
                      <li>Juegos</li>
                    </ol>
                  </Card.Text>
                  {/*                   <Button variant="primary">Reservar clase</Button>
                   */}{' '}
                </Card.Body>
              </Card>
            </ColWithMarginTop>
          </Row>
        </Container>
      </div>
    </div>
  )
}
