// src/App.js
import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { CustomNavbar } from '../../components/NavBar'
import { Card, Form, Row, Spinner } from 'react-bootstrap'
import { Field } from '../../components/Field'
import { useLocation } from 'react-router'
import { useForm, useWatch } from 'react-hook-form'
import Calendar from 'react-calendar'
import './Book.css'
import 'react-calendar/dist/Calendar.css'
import { ActionSheet } from '../../components/Modal'
import { MainButton } from '../views.styles'
import { BookedClass, trackOptions } from '../../domain/models/classes.models'
import { useService } from '../../hooks/useService'
import { saveClass } from '../../domain/services/classes.service'
import { englishFormatDate, formatDate } from '../../utils/date'
import { ClassesContext } from '../../components/context/Classes.context'
import { getByTrack, getHours, getLevelOptions } from './BookYourClass.utils'
import { generateRandomString, validatePhoneNumber } from '../../utils/general'
import { ColWithMarginTop } from '../../components/StyledComponents'

export const BookYourClass: React.FC = () => {
  const location = useLocation()
  const {
    getClassesBooked,
    classes,
    onExecuteAvailableHorses,
    availableHorses,
  } = React.useContext(ClassesContext)

  const [dateSelected, onChange] = React.useState<Date | null>(new Date())
  const [modalOpened, setModalOpen] = React.useState<
    'error' | 'success' | 'none'
  >('none')
  const [isLoading, setIsLoading] = React.useState<boolean>(!classes)

  React.useEffect(() => {
    if (!!classes) {
      setIsLoading(false)
    }
  }, [classes])

  const { response, state, onExecute } = useService<string>({
    callBack: saveClass,
    executeOnStart: false,
  })
  React.useEffect(() => {
    if (state === 'error') {
      setModalOpen('error')
    }
    if (state === 'success') {
      if (response === 'OK') {
        getClassesBooked()
        setModalOpen('success')
      } else {
        setModalOpen('error')
      }
    }
  }, [state])

  const onSubmit = (values: BookedClass) => {
    onExecute({ ...values, date: dateSelected, id: generateRandomString(15) })
  }

  const {
    register,
    control,
    setValue,
    formState: { errors },
    getValues,
  } = useForm<BookedClass>({
    mode: 'all',
    defaultValues: {
      track: location?.state?.track || 'Mediana',
      level: 'Trote',
    },
  })

  React.useEffect(() => {
    if (!classes) {
      getClassesBooked()
    }
    if (!availableHorses) {
      onExecuteAvailableHorses()
    }
  }, [])

  const watchedHour = useWatch({
    control,
    name: 'hour',
  })
  const watchedPhone = useWatch({
    control,
    name: 'phone',
  })
  const watchedLevel = useWatch({
    control,
    name: 'level',
  })
  const watchedTrack = useWatch({
    control,
    name: 'track',
  })
  const watchedName = useWatch({
    control,
    name: 'name',
  })

  const getByDate = (hour): BookedClass[] => {
    const classesByDate = classes.filter(
      (classResponse) =>
        englishFormatDate(classResponse.date) ===
        englishFormatDate(dateSelected),
    )
    const classesByHour = classesByDate.filter(
      (classResponse) => classResponse.hour === hour,
    )
    return classesByHour
  }

  const getVariant = (type) => {
    if (type === watchedHour) {
      return 'Primary'
    }
    /*  if (type === disabled) {
         return 'Secondary '
       } */
    return 'Light'
  }
  const getAvailable = (hour) => {
    const maxChildren = {
      Pequeña: {
        trote: 10,
        galope: 10,
        salto: 10,
      },
      Mediana: {
        trote: 10,
        galope: 10,
        salto: 8,
      },
    }
    const classesByDay = getByDate(hour)
    const classesByTrackWithLevel = getByTrack(
      classesByDay,
      watchedTrack,
    ).filter((clasesTo) => clasesTo.level === watchedLevel)
    return (
      maxChildren[watchedTrack as any][watchedLevel.toLowerCase()] -
      classesByTrackWithLevel.length
    )
  }
  const getAvailableText = (hour) => {
    if (!watchedTrack && !dateSelected) {
      return 'Selecciona día y pista para ver plazas disponible'
    }
    if (getAvailable(hour) === 0) {
      return `Ya no quedan plazas disponibles`
    }
    if (getAvailable(hour) === 1) {
      return `${getAvailable(hour)} plaza disponible`
    }
    return `${getAvailable(hour)} plazas disponibles`
  }

  if (isLoading) {
    return <Spinner />
  }

  return (
    <div>
      {modalOpened === 'success' && (
        <ActionSheet
          isOpen
          onClose={() => setModalOpen('none')}
          title="Reserva hecha con éxito"
        >
          <p>Tu clase se ha reservado con éxito</p>
          <p>Datos:</p>
          <ul>
            <li>{`Nombre: ${watchedName}`}</li>
            <li>Día: {formatDate(dateSelected)}</li>
            <li>Hora: {watchedHour}</li>
            <li>Pista: {watchedTrack}</li>
            <li>Nivel: {watchedLevel}</li>
          </ul>
          <p>Recuerda que puedes cancelarla hasta 24 horas antes.</p>
        </ActionSheet>
      )}
      {modalOpened === 'error' && (
        <ActionSheet
          isOpen
          onClose={() => setModalOpen('none')}
          title="Ha ocurrido un error!"
        >
          <p>{response.toString()}</p>

          <p>
            En caso de ser un error no esperado, ponte en contacto con el
            centro.
          </p>
        </ActionSheet>
      )}
      <CustomNavbar />
      <form onSubmit={null} className="mb-3">
        <div className="container mt-5">
          <h1 className="mb-3" style={{ color: '#00a09c' }}>
            Reserva tu clase en el Centro hípico de Arucas
          </h1>

          <Row>
            <ColWithMarginTop className="me-5">
              <Row>
                <h6>Selecciona en que pista estás ahora mismo</h6>
                <Field control={control} name="track" label="">
                  <Form.Select {...register('track')}>
                    {trackOptions.map((type) => (
                      <option value={type} disabled={type !== 'Mediana'}>
                        {type}
                      </option>
                    ))}
                  </Form.Select>
                </Field>
                <h6 className="mt-4 mb-3">Selecciona que día te gustaría ir</h6>
                <Calendar
                  minDate={new Date()}
                  activeStartDate={null}
                  defaultActiveStartDate={null}
                  onChange={(e) => {
                    const prueba = e as Date
                    onChange(prueba)
                  }}
                  value={dateSelected}
                />
              </Row>
            </ColWithMarginTop>
            <ColWithMarginTop>
              <Row>
                <h6>Selecciona en qué horario disponible quieres ir</h6>
                <Row className="mt-2">
                  {getHours(dateSelected).map((hour) => (
                    <Card
                      bg={getVariant(hour).toLowerCase()}
                      text={
                        getVariant(hour).toLowerCase() === 'light'
                          ? 'dark'
                          : 'white'
                      }
                      style={{ minWidth: '100%', cursor: 'pointer' }}
                      className="mb-2"
                      onClick={() => {
                        setValue('hour', hour as any)
                      }}
                    >
                      <Card.Body>
                        <Card.Title>{`Horario de ${hour}`}</Card.Title>
                        <Card.Text>{`${getAvailableText(hour)}`}</Card.Text>
                      </Card.Body>
                    </Card>
                  ))}
                </Row>
              </Row>
            </ColWithMarginTop>
          </Row>
          <Row>
            <ColWithMarginTop className="me-5">
              <Row>
                <Field control={control} label="Nombre" name="name">
                  <Form.Control
                    placeholder="Maria"
                    {...register('name', { required: true })}
                  />
                </Field>
                <Field control={control} label="Apellidos" name="surname">
                  <Form.Control
                    placeholder="Perez Gomez"
                    {...register('surname', { required: true })}
                  />
                </Field>
              </Row>
            </ColWithMarginTop>
            <ColWithMarginTop>
              <Row>
                <Row className="mt-2">
                  <h6>¿Qué nivel tienes?</h6>
                  <Field control={control} name="level" label="">
                    <Form.Select {...register('level')}>
                      {getLevelOptions(watchedTrack).map((type) => (
                        <option
                          value={type}
                          onChange={() => setValue('hour', null)}
                        >
                          {type}
                        </option>
                      ))}
                    </Form.Select>
                  </Field>
                  <Field
                    control={control}
                    label="Número de teléfono"
                    name="phone"
                  >
                    <Form.Control
                      placeholder="666666666"
                      {...register('phone', {
                        required: 'Este campo es obligatorio',
                        validate: validatePhoneNumber,
                      })}
                    />
                  </Field>
                </Row>
              </Row>
            </ColWithMarginTop>
          </Row>

          <h5 style={{ marginTop: '16px' }}>
            Recuerda que podrás cancelar tu clase hasta 24 horas antes
          </h5>
          <MainButton
            style={{ marginTop: '16px' }}
            className="d-grid"
            disabled={
              Object.keys(errors).length > 0 ||
              !watchedHour ||
              !watchedTrack ||
              !dateSelected ||
              !watchedName ||
              !watchedPhone ||
              !watchedLevel
            }
            type={'button'}
            onClick={() => onSubmit(getValues())}
          >
            Reservar clase
          </MainButton>
        </div>
      </form>
    </div>
  )
}
