import {
  BookedClass,
  weekdaysHoursOptions,
  weekenddaysHoursOptions,
} from '../../domain/models/classes.models'

const isWeekday = (dateSelected: Date) => {
  const currentDayOfWeek = dateSelected.getDay()
  console.log('currentDayOfWeek', currentDayOfWeek)

  return currentDayOfWeek < 6 && currentDayOfWeek !== 0
}

export const getHours = (date: Date) => {
  if (isWeekday(date)) {
    return weekdaysHoursOptions
  }
  return weekenddaysHoursOptions
}

export const getLevelOptions = (watchedTrack) => {
  if ((watchedTrack as any) === 'Pequeña') {
    return ['Paso', 'Trote']
  }
  return ['Trote', 'Galope', 'Salto']
}
export const getByTrack = (
  classesToFilter: BookedClass[],
  track,
): BookedClass[] => {
  const classesByTrack = classesToFilter.filter(
    (classResponse) => classResponse.track === track,
  )

  return classesByTrack
}
