import React from 'react'

export type ServiceState = 'waiting' | 'pending' | 'success' | 'error'

export const useService = <T>({
  callBack,
  executeOnStart = true,
  params,
}: {
  callBack: (param?: any) => Promise<T>
  executeOnStart?: boolean
  params?: {}
}): { response: T; state: ServiceState; onExecute: any } => {
  const [response, setResponse] = React.useState<any>('')
  const [state, setState] = React.useState<ServiceState>('waiting')

  const getInfo = async (params?: {}) => {
    try {
      setState('pending')
      const result = await callBack(params)
      console.log('result', result)
      setResponse(result)
      setState('success')
      return result
    } catch (error) {
      console.log('error', error)

      setState('error')
      return null
    } finally {
      /*       if (!isUndefined(this.onAfterCallback)) {
              this.onAfterCallback()
            } */
    }
  }

  const onExecute = (params?: {}) => getInfo(params)

  React.useEffect(() => {
    if (executeOnStart) {
      getInfo(params)
    }
  }, [])

  return { response, state, onExecute }
}
