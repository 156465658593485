// src/App.js
import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { CustomNavbar } from '../../components/NavBar'
import { ColorContainer, MainImg } from '../views.styles'
import { Card, Col, Container, Row } from 'react-bootstrap'
import Gabri from '../../imgs/gabri.jpg'
import Yessi from '../../imgs/yessica.jpg'
import Tino from '../../imgs/Tino.jpg'
import Yaque from '../../imgs/Yaque.jpg'
import { EffectFade, Navigation, Pagination, Autoplay } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import alumnaconpenelope from '../../imgs/landing/alumnaconpenelope.jpg'
import pistaCircular from '../../imgs/landing/Circulo.jpg'
import yessiSaltando from '../../imgs/landing/yessicaPruebaMuro.jpg'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/effect-fade'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

/* import './styles.css' */

export function Landing() {
  return (
    <div>
      <CustomNavbar blackTheme />
      <Swiper
        effect={'fade'}
        navigation={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        modules={[EffectFade, Navigation, Pagination, Autoplay]}
        className="mySwiper"
      >
        <SwiperSlide>
          <MainImg imgbg={null} />
        </SwiperSlide>
        <SwiperSlide>
          <MainImg imgbg={yessiSaltando} />
        </SwiperSlide>
        <SwiperSlide>
          <MainImg imgbg={pistaCircular} />
        </SwiperSlide>
        <SwiperSlide>
          <MainImg imgbg={alumnaconpenelope} position="center center" />
        </SwiperSlide>
        ...
      </Swiper>

      <div className="container mt-5">
        <title>Centro Hípico Ciudad de Arucas</title>

        <main className="d-flex flex-column justify-content-center align-items-center">
          <h2 className="mb-4">Tu hípica en Gran canaria</h2>
          <p className="text-muted">En arucas encontrarás tu centro hípico.</p>
          <p className="text-muted">
            Un lugar donde aprender y dar hospedaje a tu equino.
          </p>
        </main>
      </div>
      <ColorContainer>
        <h3 style={{ textAlign: 'center', color: 'white' }}>Conócenos</h3>
        <Container className="mt-4 mb-4">
          <Row>
            <Col xs={6} lg={3}>
              <Card>
                <Card.Body>
                  <Card.Title>Yessica</Card.Title>
                  <Card.Img variant="top" src={Yessi} />
                </Card.Body>
              </Card>
            </Col>
            <Col xs={6} lg={3}>
              <Card>
                <Card.Body>
                  <Card.Title>Yaqueline</Card.Title>
                  <Card.Img variant="top" src={Yaque} />
                </Card.Body>
              </Card>
            </Col>
            <Col xs={6} lg={3}>
              <Card>
                <Card.Body>
                  <Card.Title>Tino Y Agustín</Card.Title>
                  <Card.Img variant="top" src={Tino} />
                </Card.Body>
              </Card>
            </Col>
            <Col xs={6} lg={3}>
              <Card>
                <Card.Body>
                  <Card.Title>Juan Gabriel</Card.Title>
                  <Card.Img variant="top" src={Gabri} />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </ColorContainer>
      <div className="d-flex flex-column justify-content-center align-items-center  p-4">
        <p className="text-muted">
          «Sólo el arte de cada jinete encontrará la justa medida entre
          impulsión y fuerza, que lleva consigo el verdadero equilibrio.” Álvaro
          Domecq
        </p>
      </div>
    </div>
  )
}
