import styled from 'styled-components'
import Navbar from 'react-bootstrap/Navbar'

export const NavbarStyled = styled(Navbar)`
  background-color: rgba(0, 0, 0, 0.6) !important;
  position: fixed !important;
  width: 100%;

  color: white !important;

  > button {
    color: white !important;
  }
`
