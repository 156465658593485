import * as React from 'react'
/* import { User } from '../../models/users.models'
import { useService } from '../../hooks/useService'
import { getuserInfo } from '../../domain/services/users.service' */
import Spinner from 'react-bootstrap/Spinner'
import {
  BookedClass,
  HorsesAvailable,
} from '../../domain/models/classes.models'
import { useService } from '../../hooks/useService'
import {
  getAvailableHorses,
  getClasses,
} from '../../domain/services/classes.service'

export interface ClassesContextType {
  isLoading: boolean
  classes: BookedClass[]
  getClassesBooked: (dateFrom?: Date) => void
  setClasses: (sessionData: BookedClass[], callback?: () => void) => void
  onExecuteAvailableHorses: () => void
  availableHorses: HorsesAvailable[]
}

const ClassesContextInfo: ClassesContextType = {
  isLoading: false,
  classes: null,
  getClassesBooked: (dateFrom?: Date) => null,
  setClasses: (sessionData: BookedClass[], callback?: () => void) => undefined,
  onExecuteAvailableHorses: () => null,
  availableHorses: [],
}

export const ClassesContext =
  React.createContext<ClassesContextType>(ClassesContextInfo)

export const ClassesProvider = ({ children }) => {
  const [classes, setClasses] = React.useState<BookedClass[]>(null)
  const [isLoading, _setIsLoading] = React.useState<boolean>(false)

  const { onExecute, response, state } = useService<BookedClass[]>({
    callBack: getClasses,
    executeOnStart: false,
    params: { dateFrom: new Date() },
  })

  const {
    onExecute: onExecuteAvailableHorses,
    response: availableHorses,
    /*     state: availableHorsesState,
     */
  } = useService<HorsesAvailable[]>({
    callBack: getAvailableHorses,
    executeOnStart: false,
  })

  const getClassesBooked = (dateFrom?: Date) => {
    onExecute({ dateFrom: dateFrom ?? new Date() })
  }

  React.useEffect(() => {
    if (!!response) {
      setClasses(response)
    }
  }, [response])

  return (
    <ClassesContext.Provider
      value={{
        classes,
        isLoading: isLoading || state === 'waiting',
        setClasses,
        getClassesBooked,
        onExecuteAvailableHorses,
        availableHorses,
      }}
    >
      {isLoading ? <Spinner animation="border" /> : children}
    </ClassesContext.Provider>
  )
}
